import { FC } from "react";
import styled from "styled-components";
import { device } from "../../styles/BreakPoints";

interface MainContainerProps {
  content: JSX.Element;
  moveBackground: boolean;
}

const MainContainer = styled.div`
  flex-grow: 1; /* This allows the main content to expand and fill available space */
  background-color: #ffffff;
  overflow-y: auto; /* Allows scrolling if content overflows */
  // border: yellowgreen solid 8px;
  position: relative;
`;

// // Styled component for image container
// const ImageContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   //justify-content: center;
//   //gap: 20px;
//   perspective: 10000px; /* Adds a 3D perspective */
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   position: absolute;
//   z-index: 1; /* Push the image container to the back */
//
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     transform-origin: center; /* Sets the origin point for rotation */
//     animation: heartbeat 8s infinite ease-in-out;
//     opacity: 0.05;
//   }
//
//   @keyframes heartbeat {
//     0%,
//     100% {
//       transform: scale(1);
//     }
//     50% {
//       transform: scale(1.1);
//     }
//   }
// `;

// Styled component for individual images
const StyledImage = styled.img`
  width: 300px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const Container = styled.div`
  justify-content: center;
  align-content: center;
  text-align: left;
  position: relative; /* Ensures the text container stays on top of the background */
  z-index: 2; /* Keep content above the image */
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  margin: 40px;
  padding: 4rem;
  // border: yellowgreen solid 8px;
  //box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow to the content */
  @media ${device.md} {
    margin: 40px;
    padding: 2rem;
  }
  @media ${device.sm} {
    margin: 15px;
    padding: 1rem;
  }
`;
const SmallHeading = styled.p`
  color: #9370db;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.9rem;
  position: relative;
  font-size: 1.9rem;
  &::after {
    content: "";
    display: block;
    width: 180px;
    height: 2px;
    background-color: #9370db;
    margin-top: 0.5rem;
  }
  @media ${device.md} {
    font-size: 1.7rem;
  }
  @media ${device.sm} {
    font-size: 1.4rem;
  }
`;

const LargeHeading = styled.h1`
  font-weight: 700;
  color: #4d4d4d;
  line-height: 1.8;
  @media ${device.lg} {
    font-size: 1.9rem;
  }
  @media ${device.md} {
    font-size: 1.7rem;
  }
  @media ${device.sm} {
    font-size: 1.4rem;
  }
`;

// List styling inside the heading
const CustomList = styled.ul`
  list-style-type: none;
  padding-left: 20px; /* Indentation for the entire list */
  li {
    margin-bottom: 0.07rem;
  }
  @media ${device.lg} {
    font-size: 1.6rem;
  }
  @media ${device.md} {
    font-size: 1.4rem;
  }
  @media ${device.sm} {
    font-size: 1.2rem;
  }
`;

const Paragraph = styled.p`
  color: #4d4d4d;
  margin-top: 1rem;
  line-height: 1.6;
  @media ${device.lg} {
    font-size: 1.2rem;
  }
  @media ${device.md} {
    font-size: 1rem;
  }
  @media ${device.sm} {
    font-size: 0.9rem;
  }
`;

const Button = styled.button`
  background-color: #765aaf;
  color: #fff;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #6f5da6;
  }
`;

interface MainContainerProps {
  content: JSX.Element;
  moveBackground: boolean;
}

const ImageContainer = styled.div<{ moveBackground: boolean }>`
  display: flex;
  flex-wrap: wrap;
  perspective: 10000px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: center;
    opacity: 0.05;
    animation: ${({ moveBackground }) =>
      moveBackground ? "heartbeat 8s infinite ease-in-out" : "none"};
  }

  @keyframes heartbeat {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
`;

const Body: FC<MainContainerProps> = ({ content, moveBackground }) => {
  return (
    <MainContainer>
      <ImageContainer moveBackground={moveBackground}>
        <img src="/FloatingBackground.png" alt="Background" />
      </ImageContainer>
      <Container> {content}</Container>
    </MainContainer>
  );
};

export default Body;
