import styled, { ThemeProvider } from "styled-components";
import Navbar from "../components/Navbar";
import { Container, GlobalStyles } from "../styles/Global";
import { theme } from "../styles/Theme";
import Footer from "../components/Footer";
import Body from "../components/Body";
import React, { FC, useState } from "react";
import { device } from "../styles/BreakPoints";
import { FaQuestionCircle } from "react-icons/fa";

const FAQInfo = styled.div`
  width: 100%;
  color: #6f5da6;
  border: green solid 4px;
`;
const FAQHeading = styled.h1`
  color: #9370db;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.9rem;
  position: relative;
  font-size: 1.9rem;
  border: blue solid 4px;
  &::after {
    content: "";
    display: block;
    width: 180px;
    height: 2px;
    background-color: #9370db;
    margin-top: 0.5rem;
  }
  @media ${device.md} {
    font-size: 1.7rem;
  }
  @media ${device.sm} {
    font-size: 1.4rem;
  }
`;

const FAQQuestionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  border: cornflowerblue solid 4px;
`;

const Question = styled.h3`
  font-weight: 700;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.8;
  font-size: 1.4rem;
  justify-content: left;
  margin-left: 10px;
  flex-grow: 1; /* Ensures the question text takes available space */
  border: mediumpurple solid 4px;
  @media ${device.md} {
    font-size: 1.2rem;
  }
  @media ${device.sm} {
    font-size: 1rem;
  }
`;
const Paragraph = styled.p`
  color: #4d4d4d;
  margin-top: 1rem;
  margin-left: 1rem;
  line-height: 1.6;
  font-size: 1.2rem;
  align-content: center;
  justify-content: left;
  white-space: pre-line;
  border: salmon solid 4px;
  @media ${device.md} {
    font-size: 1.1rem;
  }
  @media ${device.sm} {
    font-size: 1rem;
  }
`;

const Caret = styled.span`
  transition: transform 0.3s ease;
  font-size: 1rem;
  align-content: center;
  justify-content: center;
  padding-top: 5px;
  padding-right: 5px;
  border: yellow solid 4px;
`;

const CaretOpen = styled.span`
  transform: rotate(90deg); /* Rotates the caret when the FAQ is open */
  border: orange solid 4px;
`;

// interface FAQItem {
//   question: string;
//   answer: string;
// }
//
// interface FAQProps {
//   faqs: FAQItem[];
// }

const FAQ = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState<number | null>(
    null,
  );
  const toggleFAQ = (index: number) => {
    setOpenQuestionIndex(openQuestionIndex === index ? null : index);
  };
  <Caret>&#x25B6; </Caret>;
  {
    /*<CaretOpen    className={`caret ${openQuestionIndex === index ? "open: ""`}>*/
  }

  {
    /*</CaretOpen>*/
  }
  const faqs = [
    {
      question:
        "Do I need to have a finished business product before I can benefit from AI?",
      answer:
        "No. We can identify use cases and develop AI solutions, and possibly an API, for your future product to tap into.",
    },
    {
      question: "Do I need to have a detailed plan of uses cases AI can solve?",
      answer:
        "No. It is helpful to have an idea of what you want to achieve with AI, but we can help you refine your idea and develop it into a product.",
    },
    {
      question:
        "Do I need to have a ready list of requirements for the AI product?",
      answer:
        "No. You don’t necessarily need to have a complete, ready list of requirements for your AI product at the outset, but having a general idea of your goals and needs will significantly help in the development process.",
    },
    {
      question:
        "Do I need to have a ready list of requirements for the AI product to achieve?",
      answer:
        "No. We will help you identify use cases, however, having a general idea of your goals and needs will significantly help in the time given for the initial consultation. We suggest asking your employees what their most time consuming and mundane tasks are or what insights does the company need to meet their goals.",
    },
    {
      question: "How much does it cost to get a consultation?",
      answer: "Consultations are in amount of $500 USD.",
    },
    {
      question: "What will I be provided at the end of the consultation?",
      answer:
        "Within 3-5 business days you will be provided with a detailed report of your future AI. " +
        "This report will include: \n " +
        "1) List of use cases, \n" +
        "2) List of potential AI solutions, \n" +
        "3) Monthly resource consumption costs of running the AI compared to the potential costs savings.\n  " +
        "4) Architecture Diagram of the AI solution. \n " +
        "5) Detailed steps on how to implement the AI solution given you decide your internal development team is ready to take on the task.\n  " +
        "6) A Proof of Concept (POC) demo of the AI solution, with the code stored in a private GitHub repository accessible exclusively to you.",
    },
    {
      question: "How long does it take to implement the AI solution?",
      answer:
        "Most applications take 4-6 weeks to implement. " +
        "If solution is required that is exceptionally complex, , " +
        "the implementation time may take be longer.",
    },
    {
      question: "How much does the AI solution cost?",
      answer: "Solutions are billed at the weekly rate of $5K USD.",
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Container>
        <Navbar />
        <Body
          moveBackground={false}
          content={
            <FAQInfo>
              <FAQHeading>FAQ</FAQHeading>
              {faqs.map((faq, index) => (
                <FAQQuestionContainer key={index}>
                  <Question onClick={() => toggleFAQ(index)}>
                    {faq.question}
                  </Question>
                  {openQuestionIndex === index && (
                    <Paragraph>{faq.answer}</Paragraph>
                  )}
                </FAQQuestionContainer>
              ))}
            </FAQInfo>
          }
        ></Body>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default FAQ;
