import styled from "styled-components";
import React, { useEffect, useState } from "react";
import LinkedInIcon from "../../Icon/LinkedIn";
import GitHub from "../../Icon/GitHub";
import { device } from "../../styles/BreakPoints";
import { Link } from "react-router-dom";

// Container that centers the entire navbar horizontally
const CenteredFooterContainer = styled.nav`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: white;
  max-width: 100vw;
  // border: orange solid 4px;
  box-shadow:
    0 -4px 8px rgba(0, 0, 0, 0.2),
    0 -2px 4px rgba(142, 124, 195, 0.5);
  z-index: 1000;
`;

const FooterRows = styled.div`
  grid-template-columns: 1fr; /* 1 column taking up 1 fraction unit */
  // border: pink solid 4px;
  grid-template-rows: repeat(
    2,
    auto
  ); /* 2 rows with automatic height based on content */
`;

const FooterContainer = styled.div`
  background-color: #fff;
  display: flex;
  // border: blue solid 4px;
`;

const FooterInner = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three equal columns */
  grid-gap: 20px; /* Space between columns */
  width: 100%;
  margin: 0 auto; /* Centers the grid content */
  // border: purple solid 8px;
`;

const FooterInnerColumns = styled.div`
  display: flex;
  flex-direction: column;
  // border: deeppink solid 8px;
  max-width: 100%; /* Ensures it doesn't exceed the parent width */
  // box-sizing: // border-box; /* Includes padding and // border in the element's total width and height */
  overflow: hidden; /* Prevents the content from spilling out if it exceeds the size */
`;

const FooterLogoContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  // border: pink solid 8px;
  max-width: 100%; /* Ensures it doesn't exceed the parent width */
  // box-sizing: // border-box; /* Includes padding and // border in the element's total width and height */
  // overflow: hidden; /* Prevents the content from spilling out if it exceeds the size */
`;

const FooterLogo = styled.div`
  display: grid;
  align-items: center;
  overflow: hidden;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  max-width: 100%;
  justify-content: center;
  // border: greenyellow solid 8px;
  grid-template-columns: repeat(1, 2fr);
  img {
    min-width: 14rem;
    min-height: 9rem;
    max-width: 14rem;
    max-height: 9rem;
  }

  @media ${device.md} {
    img {
      min-width: 8rem;
      min-height: 6rem;
      max-width: 14rem;
      max-height: 9rem;
    }
  }
  @media ${device.sm} {
    img {
      min-width: 6rem;
      min-height: 4rem;
      max-width: 8rem;
      max-height: 6rem;
    }
  }
`;

const FooterTitle = styled.h3`
  padding-top: 30px;
  //padding-bottom: 14px;
  color: #6f5da6;
  margin-bottom: 10px;
  // border: greenyellow solid 8px;
  //align-content: center;
  justify-content: left;
  word-wrap: break-word;
  gap: 20px;
  /* Change to column layout for smaller screens */
  @media ${device.lg} {
    font-weight: 400;
    font-size: 22px;
  }

  @media ${device.md} {
    font-weight: 500;
    font-size: 18px;
  }

  @media ${device.sm} {
    font-weight: 500;
    font-size: 14px;
  }
`;

const FooterElements = styled(Link)`
  font-weight: 300;
  color: #6f5da6;
  text-decoration: none;
  margin-bottom: 10px;
  // border: peachpuff solid 8px;
  align-content: center;
  justify-content: left;
  word-wrap: break-word;
  &:hover {
    color: #9370db;
  }
  /* Change to column layout for smaller screens */
  @media ${device.lg} {
    font-size: 16px;
    gap: 20px;
  }

  @media ${device.md} {
    font-size: 14px;
    gap: 16px;
  }

  @media ${device.sm} {
    font-size: 11px;
    gap: 10px;
  }
`;
const SocialIconsContainer = styled.div`
  display: flex;
  list-style: none;
  flex-direction: row; /* Default layout: row */
  gap: 40px;
  align-items: center;
  justify-content: center;

  @media screen and (${device.md}) {
    display: flex; /* Ensure that flexbox is applied */
    flex-direction: column; /* Stack items vertically */
    gap: 20px; /* Adds space between the flex items */
    align-items: center; /* Align items horizontally in the center */
    margin: 0 auto; /* Center the container */
  }
`;
const SocialIcons = styled.a`
  display: flex;
  list-style: none;
  // border: orange solid 8px;

  li {
    a {
      color: #555;
      text-decoration: none;
      font-size: 24px; /* Increase font size for icons */
      transition: font-size 0.3s ease; /* Smooth size change on hover */

      &:hover {
        color: #d14124;
        font-size: 28px; /* Slightly increase icon size on hover */
        transition: font-size 0.3s ease; /* Smooth size change on hover */
      }
    }
  }
`;

const ContainerCopyright = styled.div`
  // border: yellow solid 8px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`;

const Copyright = styled.div`
  text-align: center;
  padding: 20px 20px;
  font-size: 14px;
  color: #6f5da6;
  width: 100%;
  position: relative; /* Add this to position the pseudo-element */
  // border: mediumpurple solid 8px;
  /* Full width content but a shorter top // border */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 35%; /* Adjust this to control how long you want the // border */
    border-top: #9370db solid 1px;
  }
`;

const Footer = () => {
  const [logoSrc, setLogoSrc] = useState("/LinkedInCompanyLogo.png");

  useEffect(() => {
    const handleResize = () => {
      const dt = device.md.split(" ")[1].replace("px)", "");
      const mw = parseInt(dt);
      if (window.innerWidth <= mw) {
        setLogoSrc("/OriginalLogoSymbol.png"); // Change to mobile logo
      } else {
        setLogoSrc("/TransparentLogo.png"); // Default logo for larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    // Run initially to set the correct logo on load
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <CenteredFooterContainer>
      <FooterRows>
        <FooterContainer>
          <FooterInner>
            <FooterInnerColumns>
              <FooterLogoContainer to="/">
                <FooterLogo>
                  <img src={logoSrc} alt="The Papermasters Logo" />
                </FooterLogo>
              </FooterLogoContainer>

              {/*<FooterLink href="#">Best Practices</FooterLink>*/}
              {/*<FooterLink href="#">Cost Conscious</FooterLink>*/}
              {/*<FooterLink href="#">Low Maintenance Architecture</FooterLink>*/}
              <SocialIconsContainer>
                <SocialIcons
                  href="https://www.linkedin.com/company/thepapermasters"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon />
                </SocialIcons>
                <SocialIcons
                  href="https://github.com/thepapermasters"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GitHub />
                </SocialIcons>
              </SocialIconsContainer>
            </FooterInnerColumns>
            <FooterInnerColumns>
              <FooterTitle>Services</FooterTitle>
              <FooterElements to="/">Generative AI</FooterElements>
              <FooterElements to="/">Data Migration</FooterElements>
              <FooterElements to="/">API Development</FooterElements>
              <FooterElements to="/">MLOps & DataOps</FooterElements>
              <FooterElements to="/">Full-Stack Development</FooterElements>
            </FooterInnerColumns>
            <FooterInnerColumns>
              <FooterTitle>Products</FooterTitle>
              <FooterElements to="/">Databricks</FooterElements>
              <FooterElements to="/aws">AWS Partner</FooterElements>
              <FooterElements to="/">Applications built on OSS</FooterElements>
            </FooterInnerColumns>
            <FooterInnerColumns>
              <FooterTitle>Company</FooterTitle>
              {/*<FooterLink href="#">Blog</FooterLink>*/}
              {/*<FooterLink href="#">Our Team</FooterLink>*/}
              {/*<FooterElements to="/">Documents</FooterElements>*/}
              {/*<FooterElements to="/faq">FAQ</FooterElements>*/}
              {/*<FooterElements to="/">About Us</FooterElements>*/}
              <FooterElements to="/contact">Contact Us</FooterElements>
              <FooterElements to="/privacy">Privacy Policy</FooterElements>
            </FooterInnerColumns>
          </FooterInner>
        </FooterContainer>
        <ContainerCopyright>
          <Copyright>© 2024 The Papermasters. All Rights Reserved.</Copyright>
        </ContainerCopyright>
      </FooterRows>
    </CenteredFooterContainer>
  );
};

export default Footer;
