import React, { ChangeEvent, FormEvent, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Navbar from "../components/Navbar";
import { Container, GlobalStyles } from "../styles/Global";
import { theme } from "../styles/Theme";
import Footer from "../components/Footer";
import Body from "../components/Body";
import { device } from "../styles/BreakPoints";

const ContactInfo = styled.div`
  width: 100%;
  color: #6f5da6;
  // border: green solid 4px;
`;

const ContactHeading = styled.h1`
  color: #9370db;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.9rem;
  position: relative;
  font-size: 1.9rem;
  &::after {
    content: "";
    display: block;
    width: 180px;
    height: 2px;
    background-color: #9370db;
    margin-top: 0.5rem;
  }
  @media ${device.md} {
    font-size: 1.7rem;
  }
  @media ${device.sm} {
    font-size: 1.4rem;
  }
`;

const Paragraph = styled.p`
  color: #4d4d4d;
  margin-top: 1rem;
  line-height: 1.6;
  font-size: 1.2rem;
  @media ${device.lg} {
    font-size: 1.2rem;
  }
  @media ${device.md} {
    font-size: 1.1rem;
  }
  @media ${device.sm} {
    font-size: 1rem;
  }
`;

const Services = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Container>
        <Navbar />
        <Body
          moveBackground={false}
          content={
            <ContactInfo>
              <ContactHeading>AI Application Use Cases</ContactHeading>
              <Paragraph>
                Following are example uses cases. <br /> There are many more.
              </Paragraph>
            </ContactInfo>
          }
        ></Body>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default Services;
