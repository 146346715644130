import { ChangeEvent, FormEvent, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Navbar from "../components/Navbar";
import { Container, GlobalStyles } from "../styles/Global";
import { theme } from "../styles/Theme";
import Footer from "../components/Footer";
import Body from "../components/Body";
import { device } from "../styles/BreakPoints";
import Card from "../components/Card";

const SmallHeading = styled.h1`
  color: #9370db;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.9rem;
  position: relative;
  font-size: 1.9rem;
  &::after {
    content: "";
    display: block;
    width: 180px;
    height: 2px;
    background-color: #9370db;
    margin-top: 0.5rem;
  }
  @media ${device.md} {
    font-size: 1.7rem;
  }
  @media ${device.sm} {
    font-size: 1.4rem;
  }
`;

const LargeHeading = styled.h1`
  font-weight: 700;
  color: #4d4d4d;
  line-height: 1.8;
  font-size: 1.8rem;
  @media ${device.lg} {
    font-size: 1.8rem;
  }
  @media ${device.md} {
    font-size: 1.6rem;
  }
  @media ${device.sm} {
    font-size: 1.4rem;
  }
`;

// List styling inside the heading
const CustomList = styled.ul`
  list-style-type: none;
  font-size: 1.4rem;
  padding-left: 20px; /* Indentation for the entire list */
  li {
    margin-bottom: 0.09rem;
  }
  @media ${device.lg} {
    font-size: 1.4rem;
  }
  @media ${device.md} {
    font-size: 1.2rem;
  }
  @media ${device.sm} {
    font-size: 1rem;
  }
`;
const Paragraph = styled.p`
  color: #4d4d4d;
  margin-top: 1rem;
  line-height: 1.6;
  font-size: 1.2rem;
  @media ${device.lg} {
    font-size: 1.2rem;
  }
  @media ${device.md} {
    font-size: 1.1rem;
  }
  @media ${device.sm} {
    font-size: 1rem;
  }
`;

const Home = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Container>
        <Navbar />
        <Body
          moveBackground={true}
          content={
            <>
              <SmallHeading>
                Trust the Engineers at The Papermasters
              </SmallHeading>
              <LargeHeading>
                Affordable Software Consulting and Development Services
                <br />
                Best Practice Event Driven Architectures
                <br />
                Software Solutions that are:
                <CustomList>
                  <li>Scalable</li>
                  <li>Affordable</li>
                  <li>Sustainable</li>
                  <li>Event-Driven</li>
                </CustomList>
              </LargeHeading>
              <Paragraph>
                We deliver affordable solutions that help companies save money
                while achieving their goals. Our mission is to make Event-Driven
                software accessible and affordable for everyone. We believe
                every company, no matter size, should have access to the
                cost-saving advantages software can provide. Bonus, we
                collaborate closely with teams, ensuring the company continues
                to benefit from best practices and how to maximize cost savings
                long into the future. Let’s build something great together!
              </Paragraph>
            </>
          }
        ></Body>
        {/*<Body*/}
        {/*  moveBackground={false}*/}
        {/*  content={*/}
        {/*    <>*/}
        {/*      <Card title={"Test"} content={"also test"} />*/}
        {/*    </>*/}
        {/*  }*/}
        {/*></Body>*/}
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default Home;
