import styled from "styled-components";

const LinkedinIcon = styled.a`
  display: inline-block;
  width: 24px;
  height: 24px;

  svg {
    fill: #0077b5; /* LinkedIn's official blue color */
    transition: fill 0.3s ease;

    &:hover {
      fill: #005582; /* Darker blue on hover */
    }
  }
`;

const LinkedInIcon = () => {
  return (
    <div>
      <LinkedinIcon
        href="https://www.linkedin.com/company/thepapermasters"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path d="M22.23 0H1.77C.79 0 0 .77 0 1.72v20.56C0 23.23.79 24 1.77 24h20.45c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.21 0 22.23 0zM7.12 20.48H3.56V9h3.56v11.48zM5.34 7.56c-1.15 0-2.08-.93-2.08-2.08s.93-2.08 2.08-2.08 2.08.93 2.08 2.08-.93 2.08-2.08 2.08zm15.14 12.92h-3.55v-5.72c0-1.36-.03-3.12-1.9-3.12-1.9 0-2.19 1.48-2.19 3v5.83H9.39V9h3.41v1.57h.05c.48-.91 1.65-1.87 3.4-1.87 3.64 0 4.31 2.39 4.31 5.5v6.28z" />
        </svg>
      </LinkedinIcon>
    </div>
  );
};

export default LinkedInIcon;
