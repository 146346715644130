import React, { FC, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../../styles/BreakPoints";
import { Hamburger } from "../../config/icons";

interface NavElementsProps {
  showNavbar: boolean;
}

// Container that centers the entire navbar horizontally
const CenteredNavbarContainer = styled.nav`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: white;
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.2),
    0 4px 8px rgba(142, 124, 195, 0.5);
  z-index: 1000;
  // border: orange solid 4px;
`;

const NavInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  // border: orange solid 8px;
  @media ${device.lg} {
    gap: 30px;
    margin: auto;
  }

  @media ${device.md} {
    gap: 15px;
    margin: auto;
  }

  @media ${device.sm} {
    gap: 10px;
    margin: auto;
  }
`;

const NavInnerColumns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  margin: 0.6rem;
  // border: deeppink solid 8px;
`;

const NavLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  margin: -20px;
  // border: greenyellow solid 2px;
  img {
    min-width: 14rem;
    min-height: 9rem;
    max-width: 14rem;
    max-height: 9rem;
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media ${device.md} {
    display: block;
    cursor: pointer;
    width: 40px; /* Adjust the width to make the icon larger */
    height: 40px; /* Adjust the height to make the icon larger */
    font-size: 32px; /* If you're using a font-based icon, increase the font size */
    padding: 10px; /* Add some padding for spacing */
  }
`;

const NavElements = styled.div<NavElementsProps>`
  max-width: 100%;
  overflow: hidden;
  ul {
    display: flex;
    justify-content: center;
    align-content: center;
    // border: mediumpurple solid 2px;
    max-width: 100%;
    overflow: hidden;
  }
  /* Hide the first element (logo) in medium screens */
  ul li:first-child {
    display: none;
  }

  li {
    align-content: center;
    padding: 20px;
  }

  ul a {
    font-size: 22px;
    font-weight: 400;
    color: #6f5da6;
    // border: lightseagreen solid 2px;
    text-decoration: none;
    white-space: nowrap; /* Prevent text from wrapping */
    max-width: 100%;
    overflow: hidden;
    &:hover {
      color: #9370db;
    }
  }

  ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
    // border: darkseagreen solid 1px;
    max-width: 100%;
    overflow: hidden;
  }

  ul a.active::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
    padding: 30px;
    // border: yellow solid 8px;
  }
  /* Media query for medium and large screens */
  @media ${device.md} {
    position: absolute;
    right: 0;
    top: 40px;
    border: #9370db solid 1px;
    background-color: #fff;
    // height: calc(100vh - 50vh);
    transition: all 0.4s ease-in;
    overflow: hidden;
    width: ${({ showNavbar }) => (showNavbar ? "240px" : "0")};

    ul {
      display: flex;
      flex-direction: column;
    }

    /* Hide the first element (logo) in medium and large screens */
    ul li:first-child {
      display: flex;
    }
  }
`;

const Navbar: FC = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  // Function to close sidebar when clicking outside of it
  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setShowNavbar(false);
    }
  };

  useEffect(() => {
    if (showNavbar) {
      // Add event listener when sidebar is open
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove event listener when sidebar is closed
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNavbar]);
  return (
    <CenteredNavbarContainer>
      <NavInner>
        <NavInnerColumns>
          <NavLogo>
            <li>
              <Link to="/">
                <img src="/TransparentLogo.png" alt="The Papermasters Logo" />
              </Link>
            </li>
          </NavLogo>
        </NavInnerColumns>
        <NavInnerColumns>
          <MenuIcon onClick={handleShowNavbar}>
            <Hamburger />
          </MenuIcon>
          <NavElements
            className={showNavbar ? "active" : undefined}
            ref={sidebarRef}
            showNavbar={showNavbar}
          >
            <ul>
              <li>
                <Link to="/">
                  <img src="/TransparentLogo.png" alt="The Papermasters Logo" />
                </Link>
              </li>
              {/*<li>*/}
              {/*  <Link to="/">Company</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="/contact">Services</Link>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to="/contact">GenAI</Link>*/}
              {/*</li>*/}
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </NavElements>
        </NavInnerColumns>
      </NavInner>
      {/*</NavbarContainer>*/}
    </CenteredNavbarContainer>
  );
};

export default Navbar;
