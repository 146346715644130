import { ChangeEvent, FormEvent, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Navbar from "../components/Navbar";
import { Container, GlobalStyles } from "../styles/Global";
import { theme } from "../styles/Theme";
import Footer from "../components/Footer";
import Body from "../components/Body";

const RadioWrapper = styled.div`
  margin-top: 20px;
`;
const RadioTitle = styled.p`
  margin: 0 0 5px 0;
  padding: 0;
`;

const SubmitBtn = styled.button`
  font-weight: 500;
  font-size: 16px;
  color: #222222;
  background: transparent;
  border: 1px solid #222222;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  min-width: 150px;
  padding: 7px 0;
  margin-top: 37px;
`;

const PrivacyPolicy = () => {
  const [appTheme, setAppTheme] = useState({ dark: false, light: false });
  const [name, setName] = useState("");
  const [error, setError] = useState(false);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name.trim()) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const onChangeTheme = (e: any) => {
    const { name } = e.target;
    if (name === "light") {
      setAppTheme({ dark: false, light: true });
    }
    if (name === "dark") {
      setAppTheme({ dark: true, light: false });
    }
  };
  const Paragraph = styled.p`
    font-size: 1.2rem;
    color: #666;
    margin-top: 1rem;
    margin-bottom: 2rem;
    line-height: 1.6;
  `;
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Container>
        <Navbar />
        <Body
          moveBackground={false}
          content={
            <Paragraph>
              <title>Privacy Policy</title>
              <body>
                <h2>Privacy Policy</h2>
                <p>
                  <br />
                  At The Papermasters L.L.C., we respect your privacy and are
                  committed to protecting your personal information. This
                  privacy policy explains how we collect, use, and disclose
                  information when you visit our website or use our services.
                </p>
                <br />
                <h3>Information We Collect</h3>
                <ul>
                  <br />
                  <li>
                    Contact information (name, email address, phone number)
                  </li>
                  <li>
                    <br />
                    Device information (IP address, browser type, operating
                    system)
                  </li>
                  <br />
                  <li>
                    Usage information (pages visited, links clicked, time spent
                    on site)
                  </li>
                  <br />
                  <li>Cookie information (see Cookie Policy below)</li>
                </ul>
                <br />
                <h3>How We Use Your Information</h3>
                <ul>
                  <br />
                  <li>To respond to your inquiries or requests</li>
                  <br />
                  <li>To provide and improve our services</li>
                  <br />
                  <li>
                    To send marketing and promotional materials (with your
                    consent)
                  </li>
                  <br />
                  <li>
                    To comply with legal obligations and enforce our legal
                    rights
                  </li>
                </ul>
                <br />
                <h3>Cookie Policy</h3>
                <br />
                <p>
                  We use cookies to improve your experience on our site and to
                  collect usage information. You can adjust your browser
                  settings to accept or reject cookies.
                </p>
                <br />
                <h3>Data Security</h3>
                <br />
                <p>
                  We take reasonable measures to protect your information from
                  unauthorized access, disclosure, and use.
                </p>
                <br />
                <h3>Data Retention</h3>
                <br />
                <p>
                  We retain your information for as long as necessary to provide
                  our services and comply with legal obligations.
                </p>
                <br />
                <h3>Your Rights</h3>
                <ul>
                  <br />
                  <li>Access and correct your personal information</li>
                  <br />
                  <li>Request deletion of your personal information</li>
                  <br />
                  <li>
                    Object to or restrict processing of your personal
                    information
                  </li>
                  <br />
                  <li>
                    Request a copy of your personal information in a portable
                    format
                  </li>
                  <br />
                </ul>
                <br />
                <h3>Changes to This Privacy Policy</h3>
                <br />
                <p>
                  We may update this privacy policy from time to time. We will
                  notify you of significant changes by posting a notice on our
                  website or sending you an email.
                </p>
                <br />
                <h3>Contact Us</h3>
                <br />
                <p>
                  If you have questions or concerns about this privacy policy,
                  please contact us at&nbsp;
                  <a href="mailto:staff@thepapermasters.com">
                    staff@thepapermasters.com
                  </a>
                  .
                </p>
              </body>
            </Paragraph>
          }
        />
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;
