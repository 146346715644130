import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
import FAQ from "./pages/FAQ";
import AWSPartner from "./pages/AWSPartner";
import Services from "./pages/Services";

export const About = () => <h2>You are in the About</h2>;

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/services" element={<Services />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/aws" element={<AWSPartner />} />
    </Routes>
  );
}

export default App;
