import styled from 'styled-components';

const GithubIcon = styled.a`
  display: inline-block;
  width: 24px;
  height: 24px;
  svg {
    fill: #000; /* GitHub's official black color */
    transition: fill 0.3s ease;

    &:hover {
      fill: #333; /* Slightly lighter black on hover */
    }
  }
`;

const GitHub = () => {
    return (
        <div>
            <GithubIcon href="https://github.com/thepapermasters" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path d="M12 .297C5.373.297 0 5.67 0 12.297c0 5.3 3.438 9.8 8.205 11.387.6.112.82-.262.82-.582 0-.287-.01-1.05-.015-2.06-3.338.726-4.042-1.61-4.042-1.61-.546-1.388-1.333-1.758-1.333-1.758-1.09-.744.084-.729.084-.729 1.205.084 1.84 1.238 1.84 1.238 1.07 1.835 2.807 1.305 3.492.998.107-.775.418-1.305.76-1.605-2.665-.302-5.466-1.332-5.466-5.93 0-1.31.467-2.382 1.235-3.22-.123-.303-.535-1.523.116-3.176 0 0 1.008-.323 3.3 1.23a11.488 11.488 0 0 1 3.005-.404c1.02.004 2.046.138 3.005.404 2.29-1.554 3.296-1.23 3.296-1.23.653 1.653.24 2.873.118 3.176.77.838 1.233 1.91 1.233 3.22 0 4.61-2.803 5.625-5.475 5.92.43.37.813 1.102.813 2.222 0 1.606-.015 2.898-.015 3.292 0 .322.218.698.825.58C20.565 22.09 24 17.593 24 12.297 24 5.67 18.627.297 12 .297z"/>
                </svg>
            </GithubIcon>
        </div>
    );
};

export default GitHub;